export const makeOrganizationsRootPath = (instanceId: string): string => {
  return `/v1/instances/${instanceId}/organizations`;
};

export const makeOrganizationBasePath = (
  instanceId: string,
  organizationIDorSlug: string,
): string => `${makeOrganizationsRootPath(instanceId)}/${organizationIDorSlug}`;

export const makeOrganizationLogoPath = (
  instanceId: string,
  organizationIDorSlug: string,
): string =>
  `${makeOrganizationBasePath(instanceId, organizationIDorSlug)}/update_logo`;

export const makeDeleteOrganizationLogoPath = (
  instanceId: string,
  organizationIDorSlug: string,
): string =>
  `${makeOrganizationBasePath(instanceId, organizationIDorSlug)}/logo`;

export const makeOrganizationMembershipsPath = (
  instanceId: string,
  organizationIDorSlug: string,
): string =>
  `${makeOrganizationBasePath(instanceId, organizationIDorSlug)}/memberships`;

type OrganizationPaths = {
  logoPath: string;
  deleteLogoPath: string;
  basePath: string;
  memberships: string;
};

export const makeOrganizationPaths = (
  instanceId: string,
  organizationIDorSlug: string,
): OrganizationPaths => {
  return {
    logoPath: makeOrganizationLogoPath(instanceId, organizationIDorSlug),
    deleteLogoPath: makeDeleteOrganizationLogoPath(
      instanceId,
      organizationIDorSlug,
    ),
    basePath: makeOrganizationBasePath(instanceId, organizationIDorSlug),
    memberships: makeOrganizationMembershipsPath(
      instanceId,
      organizationIDorSlug,
    ),
  };
};
