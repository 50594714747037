import React from 'react';

import { Modal } from '@components/common';
import { useDisclosure, Button } from '@chakra-ui/react';
import { OfficeBuildingIcon } from '@heroicons/react/solid';
import Form, { FORM_ID, CreateOrganizationForm } from './Form';

import LogoUploader from '@components/organizations/organizationDetails/ProfileTab/LogoUploader';

import { useOrganizationMutators } from '@components/organizations/core';

import { useToast } from '@hooks';
import { useRouter } from 'next/router';

import { removeEmptyValuesFromObject } from '@utils/object';
import { HTTPError } from '@utils';

type LogoObject = {
  file: string;
  preview: string | null;
};

const CreateOrganizationModal = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { create, mutateCache } = useOrganizationMutators();
  const { showErrorToast, showSuccessToast } = useToast();
  const router = useRouter();

  const [logo, setLogo] = React.useState<LogoObject>({
    file: '',
    preview: null,
  });

  const [isCreatingOrganization, setIsCreatingOrganization] =
    React.useState(false);

  const closeModalAndNavigateClearParams = () => {
    void router.push({ query: { ...router.query, page: 1, query: '' } });
    onClose();
  };

  const handleOnSubmit = async (
    data: CreateOrganizationForm,
    resetForm: () => void,
    formError: (
      param: keyof CreateOrganizationForm,
      errorMessage: string,
    ) => void,
  ) => {
    try {
      setIsCreatingOrganization(true);
      const organization = await create({
        ...removeEmptyValuesFromObject(data),
        file: logo.file,
      });

      closeModalAndNavigateClearParams();
      void mutateCache();
      showSuccessToast(`Organization ${organization.name} has been created`);
      resetForm();
      setLogo({ file: '', preview: null });
    } catch (err) {
      if (err?.name === 'HTTPError') {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          const param = err?.meta?.param_name as keyof CreateOrganizationForm;
          if (param) {
            formError(param, err.message);
          }
        });
      } else {
        showErrorToast('Organization has not been created');
      }
    } finally {
      setIsCreatingOrganization(false);
    }
  };

  const updateLogo = (data: LogoObject) => {
    setLogo(data);
  };

  return (
    <>
      <Button
        aria-label='create organization'
        leftIcon={<OfficeBuildingIcon width={16} height={16} color='white' />}
        onClick={onOpen}
      >
        Create Organization
      </Button>

      <Modal
        size='md'
        closeOnEsc
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        title='Create organization'
        closeOnOverlayClick={false}
      >
        <Modal.Body>
          <Form
            onSubmit={handleOnSubmit}
            logoUploader={
              <LogoUploader
                logoURL={logo.preview}
                updateLogo={updateLogo}
                uploaderBoxRestProps={{
                  p: 0,
                  ml: 0,
                  flexDirection: 'row-reverse',
                  justifyContent: 'start',
                  width: '100%',
                  gap: 4,
                }}
              />
            }
          />
        </Modal.Body>
        <Modal.ActionFooter
          rightButtonRestProps={{ form: FORM_ID, type: 'submit' }}
          isRightButtonLoading={isCreatingOrganization}
          isRightButtonDisabled={isCreatingOrganization}
          leftButtonMessage='cancel'
          onLeftButtonClicked={onClose}
          rightButtonMessage='Create'
        />
      </Modal>
    </>
  );
};

export default CreateOrganizationModal;
