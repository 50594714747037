import React from 'react';

import { ImageUploaderBox, ImageUploaderPlaceholder } from '@components/common';

import { Image, FlexProps } from '@chakra-ui/react';

type LogoProps = {
  logoURL?: string;
  updateLogo: (file: any) => void;
  uploaderBoxRestProps?: FlexProps;
  deleteLogo?: () => void;
};

const LogoUploader = ({
  logoURL,
  updateLogo,
  uploaderBoxRestProps,
  deleteLogo,
}: LogoProps): JSX.Element => {
  const handleUpload = async (file: File) => {
    const imgData = new FormData();
    imgData.append('file', file);

    const imgUrl = URL.createObjectURL(file);

    return updateLogo({ file: imgData, preview: imgUrl });
  };

  const handleDelete = async () => {
    return deleteLogo();
  };

  return (
    <ImageUploaderBox
      label='Logo'
      handleDelete={logoURL ? () => handleDelete() : null}
      handleUpload={handleUpload}
      uploaderBoxRestProps={uploaderBoxRestProps}
    >
      {logoURL ? (
        <Image src={logoURL} height='48px' width='48px' borderRadius='base' />
      ) : (
        <ImageUploaderPlaceholder size='lg' />
      )}
    </ImageUploaderBox>
  );
};

export default LogoUploader;
